import React, { useEffect } from "react";

import { Dialog, Grid, DialogTitle, DialogActions } from "@mui/material";
import FormProvider from "../../../components/FormProviders";
import { defaultValues, schema, sanitizeRequest } from "../schemas/credentials";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import SoftBox from "../../../components/SoftBox";
import SoftButton from "../../../components/SoftButton";
import {
  MultipleSelectField,
  SelectInputField,
  TextInputField,
} from "../../../components/FormField";
import { awsRegionsOptions, s3ACLOptions } from "../../../constants/reports";
import { useCreateCredentials } from "../hooks";
import { useDisclosure } from "../../../hooks/useDisclosure";

const NewCredentialsModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  // form
  const methods = useForm({ defaultValues, resolver: yupResolver(schema) });
  const { handleSubmit, watch, setValue } = methods;
  const watchRegion = watch("region");
  // hooks
  const { mutate: createCredentials, isLoading } = useCreateCredentials();

  // functions
  const onSubmit = (data) => {
    const sanitizedData = sanitizeRequest(data);
    createCredentials(sanitizedData, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  useEffect(() => {
    if (watchRegion) {
      setValue("endpoint", `https://s3.${watchRegion}.amazonaws.com`);
    }
  }, [watchRegion]);

  return (
    <>
      <SoftButton variant="contained" color="info" sx={{ mt: -1 }} onClick={onOpen}>
        Add New Credential
      </SoftButton>
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="lg">
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            sx={{
              background: (theme) => theme.palette.grey[500],
              fontWeight: (theme) => theme.typography.h6,
              color: (theme) => theme.palette.common.white,
              px: 4,
              py: 1,
            }}
          >
            Add New Credentials
          </DialogTitle>
          <SoftBox p={2}>
            <SoftBox id="credentials-form" mt={1}>
              <Grid container columnSpacing={2}>
                <Grid item xs={4}>
                  <SelectInputField
                    name="region"
                    label="Region"
                    options={awsRegionsOptions}
                    menuPosition="fixed"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextInputField name="Bucket" label="Bucket" />
                </Grid>
                <Grid item xs={4}>
                  <SelectInputField
                    name="ACL"
                    label="ACL"
                    options={s3ACLOptions}
                    menuPosition="fixed"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextInputField name="Key" label="Folder" />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <TextInputField name="credentials.accessKeyId" label="Access Key ID" />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <TextInputField name="credentials.secretAccessKey" label="Secret Access Key" />
                </Grid>

                <Grid item xs={6}>
                  <TextInputField name="endpoint" label="Endpoint" disabled />
                </Grid>
                <Grid item xs={6}>
                  <TextInputField name="destinationName" label="Destination Name" disabled />
                </Grid>
              </Grid>
            </SoftBox>
            <DialogActions>
              <SoftButton variant="gradient" onClick={onClose}>
                Cancel
              </SoftButton>
              <SoftButton
                variant="contained"
                color="info"
                type="submit"
                disabled={isLoading}
                isLoading={isLoading}
              >
                Save
              </SoftButton>
            </DialogActions>
          </SoftBox>
        </FormProvider>
      </Dialog>
    </>
  );
};

export default NewCredentialsModal;
