import { useEffect, useMemo, useState } from "react";
import { Grid } from "@mui/material";

import { useGetBrands, useGetPaymentOptions, useGetScheduledFields } from "../hooks";
import React from "react";
import SoftBox from "../../../components/SoftBox";

import {
  DatePickerField,
  MultipleSelectField,
  SelectInputField,
  TagsInput,
  TextInputField,
  SwitchField,
  PaymentOptionSelect,
} from "../../../components/FormField";
import _ from "lodash";
import { reportDestinationOptions } from "../../../constants/reports";
// import { allTimezones } from "react-timezone-select";
import { generateOptions } from "../../../utils/options";
import { StatusEnum } from "../../../constants/status";
import { useFormContext } from "react-hook-form";
import CircularProgress from "@mui/material/CircularProgress";
import borders from "../../../assets/theme/base/borders";
import PropTypes from "prop-types";

const SchedulerForm = ({ changeScheduler, isUpdateForm }) => {
  const { borderWidth, borderColor } = borders;
  // state
  const [intervalOptions, setIntervalOptions] = useState([]);
  const { data: brands } = useGetBrands();
  const { data: paymentOptions } = useGetPaymentOptions();
  const {
    mutate: getScheduledFields,
    data: scheduledFields,
    isLoading: isFetchingScheduledFields,
  } = useGetScheduledFields();
  // // create a function that handles the form submission

  const { watch, setValue } = useFormContext();
  const reportInterval = watch("reportInterval");
  const isOneTimeReport = watch("isOneTimeReport");
  const watchDateFrom = watch("dateFrom");
  const watchDateTo = watch("dateTo");

  // options
  // const timezonesOptions = useMemo(
  //   () => generateOptionsFromList(Object.keys(allTimezones)),
  //   [allTimezones]
  // );
  const brandsOptions = useMemo(() => generateOptions(brands, false), [brands]);
  const statusOptions = [{ label: "All", value: null }].concat(
    _.map(StatusEnum, (item) => ({ value: item, label: item }))
  );
  const scheduleOptions = useMemo(() => {
    if (scheduledFields) {
      return _.map(scheduledFields.reportSchedule, (v, k) => ({ value: k, label: v }));
    }
    return [];
  }, [scheduledFields]);
  const fieldsOptions = useMemo(() => {
    if (scheduledFields) {
      return [{ label: "All", value: "All" }].concat(
        _.map(scheduledFields.reportAllowedFields, (v, k) => ({ value: k, label: v }))
      );
    }
    return [];
  }, [scheduledFields]);

  useEffect(() => {
    if (reportInterval && intervalOptions.length !== 0) {
      setValue("reportSchedule", null);
      getScheduledFields(reportInterval);
    }
  }, [reportInterval, intervalOptions]);

  useEffect(() => {
    getScheduledFields(null, {
      onSuccess: (data) => {
        const options = _.map(data.reportInterval, (v, k) => ({ value: k, label: v }));
        setIntervalOptions(options);
      },
    });
  }, []);

  useEffect(() => {
    if (scheduledFields) {
      const reportAllowedFields = scheduledFields?.reportAllowedFields;
      setValue("allFields", Object.keys(reportAllowedFields));
    }
  }, [scheduledFields]);

  useEffect(() => {
    if (isOneTimeReport) {
      setValue("reportInterval", null);
      setValue("reportSchedule", null);
    } else {
      setValue("dateFrom", null);
      setValue("dateTo", null);
    }
  }, [isOneTimeReport]);

  return (
    <SoftBox p={2}>
      <SoftBox borderBottom={`${borderWidth[1]} solid ${borderColor}`}>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={12}>
            <TextInputField name="name" label="Scheduler Name" />
          </Grid>

          <Grid item xs={6} sm={6}>
            <TagsInput
              name="emails"
              label="Emails"
              helperText="Multiple emails are excepted, Hit enter to add a new email"
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <SelectInputField
              name="reportDestination"
              label="Report Destination"
              menuPosition={"fixed"}
              options={reportDestinationOptions}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextInputField
              name="maxNumberOfTransactionsInCsv"
              label="Max Number Of Transactions In Csv"
            />
          </Grid>

          {/* <Grid item xs={6} sm={6}>
          <SelectInputField
            name="timezone"
            label="Timezone"
            menuPosition={"fixed"}
            options={timezonesOptions}
          />
        </Grid> */}
          <Grid item xs={6} sm={6}>
            <DatePickerField
              name="nextScheduleTime"
              label="Next Schedule Time"
              options={{
                allowInput: false,
                time_24hr: true,
                enableTime: true,
                minDate: "today",
              }}
              placeholder="Now"
              disabled={isUpdateForm}
            />
          </Grid>
          <Grid item xs={12} sm={12} sx={{ mt: -1, ml: 1 }}>
            <SwitchField name="excludeWeekends" label="Exclude Weekends" disabled={isUpdateForm} />
          </Grid>
        </Grid>
      </SoftBox>

      <SoftBox borderBottom={`${borderWidth[1]} solid ${borderColor}`} mt={3}>
        <Grid container columnSpacing={2}>
          {changeScheduler && (
            <Grid item xs={12}>
              <SoftBox display="flex" ml={1} mb={1}>
                <SwitchField name="isOneTimeReport" label="One Time Report" />
              </SoftBox>
            </Grid>
          )}
          {!isOneTimeReport && (
            <>
              <Grid item xs={6} sm={6}>
                <SelectInputField
                  name="reportInterval"
                  label="Report Interval"
                  menuPosition={"fixed"}
                  options={intervalOptions}
                  isClearable
                  disabled={isUpdateForm}
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                {isFetchingScheduledFields && (
                  <SoftBox
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    height="100%"
                  >
                    <CircularProgress size="20px" />
                  </SoftBox>
                )}
                {!isFetchingScheduledFields && (
                  <SelectInputField
                    name="reportSchedule"
                    label="Report Schedule"
                    menuPosition={"fixed"}
                    options={scheduleOptions}
                    isClearable
                    disabled={isUpdateForm}
                  />
                )}
              </Grid>
            </>
          )}
          {isOneTimeReport && (
            <>
              <Grid item xs={6} sm={6}>
                <DatePickerField
                  name="dateFrom"
                  label="From Date"
                  options={{
                    allowInput: false,
                    time_24hr: true,
                    enableTime: true,
                    maxDate: watchDateTo || "today",
                  }}
                  disabled={isUpdateForm}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <DatePickerField
                  name="dateTo"
                  label="To Date"
                  options={{
                    allowInput: false,
                    time_24hr: true,
                    enableTime: true,
                    minDate: watchDateFrom,
                    maxDate: "today",
                  }}
                  disabled={isUpdateForm}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={12}>
            {isFetchingScheduledFields && (
              <SoftBox
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                height="100%"
                mb={2}
              >
                <CircularProgress size="20px" />
              </SoftBox>
            )}
            {!isFetchingScheduledFields && (
              <MultipleSelectField
                name="fields"
                label="Fields"
                menuPosition={"fixed"}
                options={fieldsOptions}
                multiline={true}
                isMulti={true}
              />
            )}
          </Grid>
        </Grid>
      </SoftBox>

      <Grid container columnSpacing={2} mt={3}>
        <Grid item xs={12} sm={12}>
          <SelectInputField
            name="filters.brand"
            label="Brand"
            menuPosition={"fixed"}
            options={brandsOptions}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextInputField name="filters.merchantReference" label="Merchant Reference" />
        </Grid>
        <Grid item xs={6} sm={6}>
          <SelectInputField
            name="filters.status"
            label="Status"
            options={statusOptions}
            menuPosition={"fixed"}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <PaymentOptionSelect
            name="filters.paymentOptionId"
            label="Payment Option"
            paymentOptions={paymentOptions}
            menuPosition={"fixed"}
            useAll={false}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextInputField name="filters.email" label="Email" />
        </Grid>
      </Grid>
    </SoftBox>
  );
};
SchedulerForm.propTypes = {
  changeScheduler: PropTypes.bool,
  isUpdateForm: PropTypes.bool,
};
export default SchedulerForm;
