import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";

import { Card } from "@mui/material";

import SoftBox from "../../../../components/SoftBox";
import { useForm } from "react-hook-form";

import { useGetReports, useGetScheduledReports } from "../../hooks";
import DataTable from "../../../../examples/Tables/DataTable";
import ReportDetailsModal from "../../components/ReportDetailsModal";
import { useDisclosure } from "../../../../hooks/useDisclosure";
import FormProvider from "../../../../components/FormProviders";
import ReportFilters from "../../components/ReportFilters";
import { defaultValues, schema, sanitizeFilters } from "../../schemas/reportFilters";
import { yupResolver } from "@hookform/resolvers/yup";
import { Chip } from "@mui/material";

const statusColorEnum = {
  ERROR: "error",
  SUCCESS: "success",
  PENDING: "warning",
  PROCESSING: "info",
  COMPLETED: "success",
};

const ScheduledReports = () => {
  const { id } = useParams();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [selectedReport, setSelectedReport] = useState(null);
  const [schedulers, setSchedulers] = useState([]);
  const { data: reports, isLoading, mutate } = useGetReports();
  const { mutate: getSchedulers } = useGetScheduledReports();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  // forms
  const methods = useForm({ defaultValues, resolver: yupResolver(schema) });
  const { handleSubmit } = methods;

  const getRows = () => {
    return _.map(reports?.docs, (item) => {
      const scheduler = _.find(schedulers, (scheduler) => scheduler._id === item.scheduledReportId);
      return {
        id: item._id,
        scheduledReportId: item.scheduledReportId,
        scheduledReportName: scheduler?.name || item.scheduledReportId,
        merchantId: item.merchantId,
        generatedAt: item.generatedAt,
        reportFrom: item.reportFrom,
        reportTo: item.reportTo,
        merchantDestinationError: item.merchantDestinationError,
        processed: item?.csvMeta?.processed,
        status: (
          <Chip
            label={item.generatedReportStatus}
            color={_.get(statusColorEnum, item.generatedReportStatus, "info")}
          />
        ),
      };
    });
  };

  const onSubmit = (data) => {
    const sanitizedFilters = sanitizeFilters(data);
    mutate({
      page: currentPage,
      limit: pageSize,
      scheduledReportId: id,
      ...sanitizedFilters,
    });
  };
  const onCellClick = (row) => {
    const report = _.find(reports?.docs, (report) => report._id === row.original.id);
    setSelectedReport(report);
    onOpen();
  };
  const handlePageChange = (page) => {
    setCurrentPage(page + 1);
    mutate({
      page: page + 1,
      limit: pageSize,
      scheduledReportId: id,
      ...sanitizeFilters(methods.getValues()),
    });
  };

  useEffect(() => {
    mutate({
      page: currentPage,
      limit: pageSize,
      scheduledReportId: id,
      ...sanitizeFilters(methods.getValues()),
    });
    getSchedulers(
      { includeDeleted: false },
      {
        onSuccess: (data) => {
          setSchedulers((prev) => [...prev, ...data]);
          getSchedulers(
            { includeDeleted: true },
            {
              onSuccess: (data) => {
                setSchedulers((prev) => [...prev, ...data]);
              },
            }
          );
        },
      }
    );
  }, []);

  return (
    <SoftBox>
      <SoftBox mt={2} mb={2}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <ReportFilters />
        </FormProvider>
      </SoftBox>
      <Card sx={{ mt: 2 }}>
        <SoftBox p={2}>
          <DataTable
            table={{
              columns: [
                { Header: "ID", accessor: "id", hide: true },
                { Header: "Scheduled Report ID", accessor: "scheduledReportId", hide: true },
                { Header: "Scheduled Report Name", accessor: "scheduledReportName" },
                { Header: "Merchant ID", accessor: "merchantId", hide: true },
                { Header: "Generated At", accessor: "generatedAt" },
                { Header: "Report From", accessor: "reportFrom" },
                { Header: "Report To", accessor: "reportTo" },
                { Header: "Merchant Destination Error", accessor: "merchantDestinationError" },
                { Header: "Processed", accessor: "processed" },
                { Header: "Status", accessor: "status" },
              ],
              rows: getRows() || [],
            }}
            isClickable={true}
            canSearch={false}
            onCellClick={onCellClick}
            onPageChange={handlePageChange}
            pageCount={_.get(reports, "totalPages", 0)}
            manualPagination={true}
            rowCount={_.get(reports, "totalDocs", 0)}
            isLoading={isLoading}
            columnsFilter={true}
            currentPage={currentPage}
            entriesPerPage={false}
          />
        </SoftBox>
      </Card>
      <ReportDetailsModal
        report={selectedReport}
        onClose={onClose}
        isOpen={isOpen}
        schedulers={schedulers}
      />
    </SoftBox>
  );
};

export default ScheduledReports;
